<template>
  <div class="my-0 mx-0 px-0">
    <AHeader/>
    <router-view />
    <v-col class="py-0" cols="12">
     <Footer class="mt-2" />
    </v-col>
  </div>
</template>

<script>
import AHeader from "../../components/Website/core/AHeader";
import Footer from "../../components/Website/core/Footer";
export default {
  components: {
    AHeader,
    Footer
  },
  data: () => ({
    value: 1,
    active: true,
  }),
};
</script>
